<template>
  <div>
    <b-alert variant="danger" show>개발중인 화면입니다.</b-alert>
    <b-tabs v-model="tabIndex">
      <b-tab title="캠페인">
        <campaigns ref="tab-0"></campaigns>
      </b-tab>
      <b-tab title="AD Group">
        <adgroups ref="tab-1"></adgroups>
      </b-tab>
      <b-tab title="AD">
        <ads ref="tab-2"></ads>
      </b-tab>
      <b-tab title="API Log">
        <apilog ref="tab-3"></apilog>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import campaigns from './Campaigns.vue'
import adgroups from './ADGroups.vue'
import ads from './ADs.vue'
import apilog from './ApiLogs.vue'

export default {
  name: 'NaverADApi',
  title: '네이버 광고 API 데이터',
  components: {campaigns, adgroups, ads, apilog},
  data() {
    return {
      tabIndex: 0,
    }
  },
  watch: {
    tabIndex(v) {
      this.$refs[`tab-${v}`].render();
    },
  },
}
</script>
