<template>
  <div>
    <div v-if="busy.list" class="text-center text-dark mt-5">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <htb ref="htb" v-model="items.list" :config="htbConfig" height="calc(100vh - 300px)"></htb>
  </div>
</template>

<style scoped>
</style>

<script>

export default {
  name: 'NaverADApiLogs',
  data() {
    return {
      lastBody: {list: {}},
      items: {list: []},
      busy: {list: false, listmore: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController

      htbConfig: {
        settings: {
          contextMenu: true,
          filters: true,
          dropdownMenu: true,
        }
      }
    }
  },
  mounted() {
    this.list();
  },
  methods: {
    async list() {
      await this.$api.postTable(this, '/ad/naver/adApi', {}, {fnAssign: this.assignTableData});
      this.render();
    },
    assignTableData(e) {
      Object.entries(e).forEach(([k, v]) => {
        if (typeof v === 'object') {
          e[k] = JSON.stringify(v, null, 2);
        }
      });
    },
    render() {
      setTimeout(() => this.$refs.htb.hotInstance.render(), 0);
    },
  }
}
</script>
