<template>
  <div>
    <div v-if="busy.list" class="text-center text-dark mt-5">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <htb ref="htb" v-model="items.list" :config="htbConfig" height="calc(100vh - 300px)"></htb>
  </div>
</template>

<style scoped>
</style>

<script>
import htb from '@/views/modules/HotTableBase.vue'

export default {
  name: 'NaverCampaigns',
  components: {htb},
  data() {
    return {
      lastBody: {list: {}},
      items: {list: []},
      busy: {list: false, listmore: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController

      htbConfig: {
        settings: {
          contextMenu: true,
          filters: true,
          dropdownMenu: true,
          // dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'],
          // dropdownMenu: ['filter_by_condition', 'filter_action_bar'],
        }
      }
    }
  },
  mounted() {
    this.list();
  },
  methods: {
    async list() {
      await this.$api.postTable(this, '/ad/naver/campaigns', {}, {fnAssign: this.assignTableData});
      this.$refs.htb.hotInstance.loadData(this.items.list);
      console.log(this.items.list[0]);
    },
    assignTableData(e) {
      // e._diff = e._diff && e._diff.length || 0; // 데이터 부하 발생으로 제외
    },
    render() {
      setTimeout(() => this.$refs.htb.hotInstance.render(), 0);
    },
  }
}
</script>
